<script setup lang="ts">
const showLoginOptions = ref(true);
const { query } = useRoute();
onMounted(() => {
  if (query.login) {
    showLoginOptions.value = false;
  }
});
</script>
<template>
  <div class="flex justify-center">
    <section
      v-if="showLoginOptions"
      class="container h-screen -mt-24 mx-auto flex flex-wrap items-center justify-center px-5 py-24 text-gray-600"
    >
      <div
        class="grid grid-cols-1 md:grid-cols-2 gap-8 place-items-center w-full md:w-1/2"
      >
        <NuxtLink
          class="btn btn-blue-500 flex items-center justify-center bg-blue-500 text-xl text-white p-2 rounded-xl w-full h-24"
          to="/register"
        >
          Register School
        </NuxtLink>
        <button
          class="btn btn-blue-500 bg-blue-500 text-xl text-white p-2 rounded-xl w-full h-24"
          @click="showLoginOptions = false"
        >
          Login
        </button>
      </div>
    </section>
    <div
      v-if="!showLoginOptions"
      class="flex flex-col items-center md:w-1/2 lg:w-2/6"
    >
      <AuthLoginForm />
      <div class="flex items-center gap-1">
        <p class="text-xs">You don't have an account yet?</p>
        <nuxt-link
          class="w-fit text-sm text-blue-400 hover:text-blue-500"
          to="/register"
          >Register</nuxt-link
        >
      </div>
    </div>
  </div>
</template>
